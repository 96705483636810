.hide-in-app { display: none}
.panel {
    position: relative
}
.panel:before{
    display: block;
    position: absolute;
    font-size: 32px;
    top: 8px;
    right: 12px;
    content: '-';
    color: #aaa;
}

.panel.hidden {
    height: 56px;
    overflow-y: hidden;
}

.panel.hidden:before {
    content: '+';
    
}
a {text-decoration: none;}